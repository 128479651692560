.home {
    width: '100%';
    height: '100%';
    text-align: center;
    vertical-align: middle;
    min-height: 350px;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../images/bee.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.title {
    color: white;
    padding-top: 10%;
    font-weight: 400;
    font-size: 4.5rem;
}