.itemTable {
    padding: 15px;
    background-color: #DDD;
    position: inline; 
    /* left: 50%; */
    width: 375px;
    height: 375px;
    text-align: left;
}

.itemTitleRow {
    vertical-align: top;
    text-align: left;
    line-height: 25px;
}

.itemImage {
    width:  140px;
    height: 140px;
}

.itemNameLabel {
    padding: 5px;
    font-size: 140%;
}

.itemDescriptionLabel {
    padding: 5px;
    font-size: 100%;
}

.quantityBox {
    width: 50px;
}

.buyButton {
    width: 7em;
}

.displayTable {
	margin: auto;
}

.displayTable .tr {
	padding: 20px;
} 

.displayTable .td {
	padding: 20px;
}