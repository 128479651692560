body,
html {
	overflow-y: scroll;
  width: 100%;
}

.App { 
	text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*default styles*/

button {
	padding: 5px;
	border: 1px solid grey;
	background-color: #EEEEEE;
}

button.disabled {
	color: grey;
	border: 1px solid grey;
}

button:hover {
	background-color: lightgrey;
}

select {
	background-color: #EEEEEE;
	padding: 4px;
	border: 1px solid grey;
}

select:hover {
	background-color: lightgrey;
}

input {
	background-color: #FFF;
	border: 1px solid #C0C0C0;
}

/* Mobile quantity boxes 8*/

.quantityBoxMobile {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  width: 30px;
  height: 30px;
  border: 0px;
  text-align: center;
}
.quantityButtonMobile {
  width: 33px;
  height: 33px;
  border: 0px;
}