.leftside-position {
    position:absolute;
    top: 150px;
    left: 50%;
    transform: translate(-110%, 0%);
}
.rightside-position {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translate(10%, 0%);
}


.checkout-container {
    text-align: center;
    padding-right: 50px;
    background-color: lightgrey;
}

.checkout-container label {
    font-size: 25px;
    margin-left: 45%;
}

.btn {
	margin: 2px;
	padding: 5px;
	z-index: 10;
}
.btn.active {
    border: 1px solid black;
    background-color: lightblue;
}

.btn.inactive {
	border: 1px solid grey;
	background-color: lightgrey;
	color: grey;
}

.btn.active:hover {
    background-color: aqua;
}

.checkout-items {}
.checkout-item {}

.checkout-items table {
    /* border: 1px solid red; */
    text-align: left;
    margin: auto;
    padding-left: 30px;
    /* padding-right: 50px; */
    padding-bottom: 35px;
    /* max-width: 50%; */
}

.checkout-item td {
    padding-bottom: 20px;
    min-width: 75px;
}

/* .checkout-item input {
    width: 30px; */
/* } */

/*Finalize Menu*/

.finalize-container {
    text-align: center;
    padding-right: 75px;
    background-color: lightgrey;
}

.finalize-container table {
    padding-left: 75px;
    /* padding-right: 75px; */
}

.finalize-container td {
    padding-bottom: 20px;
}