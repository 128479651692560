.header {
    text-align: center;
    position: relative;
    width: 100%;
    /* top: 50px; */
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    /* bottom: -1px; */
    position: relative;
    list-style: none;
    padding: 12px 48px;
    cursor: pointer;
}

.react-tabs__tab--selected {
    background-color: antiquewhite;
    border: 1px solid transparent;
}

.react-tabs__tab-list {
    margin: 0;
    border-bottom: 1px solid gray;
}

.react-tabs__tab-panel {
}

.react-tabs__tab-panel--selected {
	border-top: 1px solid gray;
}


.profileIcon {
    width: 50px;
    height: 50px;

    position: absolute;
    left: 20px;
    top: 20px;
}

 /* Dropdown Button */
 .dropbtn {
    background-color: transparent;
    color: black;
    padding: 16px;
    font-size: 16px;
    border: none;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: absolute;
    top: 20px;
    /* right: 20%; */
    display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block; right: 0px;}
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #ddd;}